import { Add, DirectionsCar, ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { memo, useCallback, useEffect, useState } from 'react';
import { useCarTask } from '../../hooks/parking/useCarTask';
import TaskExpandableList from './TaskExpandableList';

const TeamView = memo(({ title, team, onAddCar, onDriveOut, isOperationalUser, onDeleteTask }) => {
  const [remarkDialog, setRemarkDialog] = useState({ open: false, task: null });
  const [remark, setRemark] = useState('');
  const [expandedCarId, setExpandedCarId] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { tasks, isLoading, refreshTasks, updateTaskStatus, updateTaskRemark, deleteTask, assignTeam } =
    useCarTask();

  // Group tasks by car
  const carsWithTasks = tasks.data?.reduce((acc, car) => {
    return {
      ...acc,
      [car.licensePlate]: {
        car,
        tasks: car.carTasks || []
      }
    };
  }, {});

  const handleAddTask = async (car) => {
    try {
      await assignTeam(car, team);
      await refreshTasks(team);
      setSnackbar({
        open: true,
        message: `Successfully assigned to ${team} team`,
        severity: 'success'
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.message || `Failed to assign to ${team} team`,
        severity: 'error'
      });
    }
  };

  const handleStatusChange = useCallback(async (task, newStatus) => {
    try {
      await updateTaskStatus(task.id, { ...task, status: newStatus });
      setSnackbar({
        open: true,
        message: 'Status updated successfully',
        severity: 'success'
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to update status',
        severity: 'error'
      });
    }
  }, []);

  const handleRemarkSave = useCallback(async () => {
    if (!remarkDialog.task) return;

    try {
      await updateTaskRemark(remarkDialog.task.id, {
        ...remarkDialog.task,
        remark
      });

      setRemarkDialog({ open: false, task: null });
      setRemark('');
      setSnackbar({
        open: true,
        message: 'Remark updated successfully',
        severity: 'success'
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to update remark',
        severity: 'error'
      });
    }
  }, [remarkDialog.task, remark]);

  const calculateDuration = (time) => {
    const date = new Date(time);
    return date.toLocaleString();
  };

  const renderCarCard = (licensePlate, carData) => {
    const { car, tasks } = carData;
    const isExpanded = expandedCarId === licensePlate;

    return (
      <Paper key={licensePlate} elevation={1} sx={{ mb: 2, overflow: 'hidden' }}>
        <Box
          sx={{
            p: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: isMobile ? 'wrap' : 'nowrap',
            gap: 2
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <DirectionsCar />
            <Typography variant="subtitle1">{licensePlate}</Typography>
          </Box>

          {car.carPark && (
            <Box sx={{ flex: 1 }}>
              <Stack direction={isMobile ? 'column' : 'row'} spacing={1}>
                <Chip
                  size="small"
                  label={`At ${car.carPark.name}, Lot: ${car.lotId}`}
                  variant="outlined"
                />
                <Chip
                  size="small"
                  label={`Parked: ${calculateDuration(car.time)}`}
                  variant="outlined"
                />
                <Chip
                  size="small"
                  label={`Season: ${car.seasonalParking}`}
                  color={car.seasonalParking === 'Yes' ? 'success' : 'default'}
                />
                {isOperationalUser && (
                  <Button variant="text" size="small" onClick={() => handleAddTask(car)}>
                    Add New Task
                  </Button>
                )}
              </Stack>
            </Box>
          )}

          <Box>
            <IconButton
              onClick={() => setExpandedCarId(isExpanded ? null : licensePlate)}
              size="small"
            >
              {isExpanded ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Box>
        </Box>

        <Collapse in={isExpanded}>
          <Divider />
          <Box sx={{ p: 2 }}>
            <Typography variant="subtitle2" sx={{ mb: 2 }}>
              Tasks ({tasks.length})
            </Typography>
            <TaskExpandableList
              tasks={tasks}
              onDriveOut={() => onDriveOut(car)}
              onStatusChange={handleStatusChange}
              onAddRemark={
                isOperationalUser
                  ? (task) => {
                      setRemarkDialog({ open: true, task });
                      setRemark(task.remark || '');
                    }
                  : undefined
              }
              onDeleteTask={isOperationalUser ? deleteTask : undefined}
            />
          </Box>
        </Collapse>
      </Paper>
    );
  };

  useEffect(() => {
    refreshTasks(team);
  }, [team, refreshTasks]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6" component="h2">
            {title}
          </Typography>
          <Button variant="contained" startIcon={<Add />} onClick={onAddCar}>
            Add Car
          </Button>
        </Box>

        <Card elevation={3}>
          <CardHeader
            title={`Cars with ${team} Tasks: ${Object.keys(carsWithTasks || {}).length}`}
          />
          <CardContent>
            {Object.entries(carsWithTasks || {}).map(([licensePlate, carData]) =>
              renderCarCard(licensePlate, carData)
            )}
          </CardContent>
        </Card>
      </Grid>

      <Dialog
        open={remarkDialog.open}
        onClose={() => setRemarkDialog({ open: false, task: null })}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>{remarkDialog.task?.remark ? 'Edit Remark' : 'Add Remark'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Remark"
            value={remark}
            onChange={(e) => setRemark(e.target.value)}
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            placeholder="Enter your remark here..."
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRemarkDialog({ open: false, task: null })}>Cancel</Button>
          <Button onClick={handleRemarkSave} variant="contained" disabled={!remark.trim()}>
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
          severity={snackbar.severity}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Grid>
  );
});

export default TeamView;
