import areaChartFill from '@iconify/icons-ant-design/area-chart';
import carFill from '@iconify/icons-ant-design/car-fill';
import bellFill from '@iconify/icons-eva/bell-fill';
import bookFill from '@iconify/icons-eva/book-fill';
import bookOpenFill from '@iconify/icons-eva/book-open-fill';
import creditCardFill from '@iconify/icons-eva/credit-card-fill';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import image2Fill from '@iconify/icons-eva/image-2-fill';
import messageCircleFill from '@iconify/icons-eva/message-circle-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import pricetagsFill from '@iconify/icons-eva/pricetags-fill';
import shakeFill from '@iconify/icons-eva/shake-fill';
import videoFill from '@iconify/icons-eva/video-fill';
import { Icon } from '@iconify/react';
import { Avatar, Box, Drawer, Link, Typography } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ChecklistIcon from '@mui/icons-material/Checklist';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DescriptionIcon from '@mui/icons-material/Description';
import DownloadIcon from '@mui/icons-material/Download';
import EmailIcon from '@mui/icons-material/Email';
import EventIcon from '@mui/icons-material/Event';
import EventAvailable from '@mui/icons-material/EventAvailable';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import RepeatIcon from '@mui/icons-material/Repeat';
import SettingsIcon from '@mui/icons-material/Settings';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import UploadIcon from '@mui/icons-material/Upload';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import account from '../../_mocks_/account';
import { MHidden } from '../../components/@material-extend';
import Logo from '../../components/Logo';
import NavSection from '../../components/NavSection';
import Scrollbar from '../../components/Scrollbar';
import SidebarDropdown from '../../components/SidebarDropdown';
import AuthContext from '../../store/AuthContext';

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  { title: 'dashboard', path: './app', icon: getIcon(pieChart2Fill) },
  { title: 'car models', path: './car-models', icon: getIcon(carFill) },
  { title: 'blog', path: './blog', icon: getIcon(fileTextFill) },
  { title: 'Leave Application', path: './leave', icon: getIcon(peopleFill) },
  { title: 'Leave Calendar', path: './leave/calendar', icon: <CalendarMonthIcon /> }
];

const sidebarPriceSettings = [
  {
    title: 'Rental Pricing Listing',
    path: './booking/price/rental',
    icon: <AttachMoneyIcon width={22} height={22} />
  },
  {
    title: 'Price Settings',
    path: './booking/price/settings',
    icon: <SettingsIcon width={22} height={22} />
  }
];

const sidebarVehicles = [
  { title: 'Vehicle Listing', path: './vehicles', icon: getIcon(carFill) },
  {
    title: 'Logcard & Insurance',
    path: './vehicles/upsert',
    icon: <UploadIcon width={22} height={22} />
  },
  {
    title: 'Vehicle Settings',
    path: './vehicles/settings',
    icon: <SettingsIcon width={22} height={22} />
  },
  { title: 'Available Vehicle Listing', path: './vehicles/available', icon: getIcon(bookFill) },
  { title: 'Disposing Vehicle Listing', path: './vehicles/disposing', icon: getIcon(bookFill) },
  { title: 'Disposal Task Management', path: './vehicles/disposal/tasks', icon: getIcon(bookFill) },
  { title: 'Inspection Vehicle Listing', path: './vehicles/inspection', icon: getIcon(carFill) },
  { title: 'Car Parking Mnaagement', path: './vehicles/car-parking', icon: getIcon(carFill) }
];

const sidebarCarParking = [
  { title: 'Car Parking Mnaagement', path: './vehicles/car-parking', icon: getIcon(carFill) }
];

const sidebarSales = [
  { title: 'OTR', path: './sales/otr', icon: getIcon(areaChartFill) },
  {
    title: 'Booking Listing',
    path: './sales/booking',
    icon: <DescriptionIcon width={22} height={22} />
  },
  { title: 'Booking Chart', path: './sales/booking-chart', icon: getIcon(areaChartFill) },
  { title: 'Customer Listing', path: './sales/customer', icon: getIcon(peopleFill) },
  {
    title: 'Jobs Listing',
    path: './sales/workshop-jobs',
    icon: <FormatListBulletedIcon width={22} height={22} />
  },
  {
    title: 'Return Jobs Listing',
    path: './sales/workshop-return-jobs',
    icon: <RepeatIcon width={22} height={22} />
  },
  {
    title: 'Appointment Listing',
    path: './sales/workshop-appointment',
    icon: <AccessTimeIcon width={22} height={22} />
  },
  { title: 'New OTR (WIP)', path: './sales/new-otr', icon: getIcon(areaChartFill) }
];

const sidebarAccounting = [
  {
    title: 'Agreement Listing',
    path: './accounting/agreements',
    icon: <DescriptionIcon width={22} height={22} />
  },
  {
    title: 'Transaction Listing',
    path: './accounting/account-reports',
    icon: <ReceiptLongIcon width={22} height={22} />
  },
  {
    title: 'User Wallet',
    path: './accounting/user-wallet',
    icon: <AccountBalanceWalletIcon width={22} height={22} />
  },
  {
    title: 'Shell Cards',
    path: './accounting/shell-card',
    icon: <CreditCardIcon width={22} height={22} />
  },
  { title: 'Bookings Accounts', path: './accounting/bookings-accounts', icon: getIcon(bookFill) },
  { title: 'Invoice Listing', path: './accounting/invoice-listing', icon: getIcon(bookFill) },
  {
    title: 'Credit Note Listing',
    path: './accounting/credit-note-listing',
    icon: getIcon(bookFill)
  },
  { title: 'Reports', path: './accounting/reports', icon: getIcon(bookFill) },
  {
    title: 'payment vouchers',
    path: './accounting/payment-vouchers',
    icon: getIcon(creditCardFill)
  },
  { title: 'refund vouchers', path: './accounting/refund-vouchers', icon: getIcon(creditCardFill) },
  {
    title: 'Bulk Apply Collections',
    path: './accounting/bulk-apply',
    icon: <AttachMoneyIcon width={22} height={22} />
  },
  {
    title: 'settings',
    path: './accounting/accounts-settings',
    icon: <SettingsIcon width={22} height={22} />
  }
];

const sidebarOperation = [
  { title: 'Workshop Vehicle Listing', path: './operation/vehicle', icon: getIcon(carFill) },
  {
    title: 'Workshop Task Listing',
    path: './operation/task',
    icon: <ChecklistIcon width={22} height={22} />
  },
  {
    title: 'Workshop Request Listing',
    path: `./operation/workshop-request`,
    icon: getIcon(bookFill)
  },
  {
    title: 'View Jobs',
    path: `./operation/workshop`,
    icon: <FormatListBulletedIcon width={22} height={22} />
  },
  { title: 'View Live Jobs', path: './operation/live', icon: getIcon(shakeFill) },
  {
    title: 'Appointment Listing',
    path: './operation/workshop-appointment',
    icon: <AccessTimeIcon width={22} height={22} />
  },
  { title: '3rd Party Tacking', path: './operation/3rd-party-tracking', icon: getIcon(carFill) },
  { title: 'Settings', path: './operation/settings', icon: <SettingsIcon width={22} height={22} /> }
];

const sidebarWorkshop = [
  { title: 'New Job | 新任务', path: './workshop/job/new', icon: getIcon(bookFill) },
  {
    title: 'View Jobs | 查询任务',
    path: './workshop/job',
    icon: <FormatListBulletedIcon width={22} height={22} />
  },
  {
    title: 'Appointment Listing',
    path: './workshop/appointment',
    icon: <AccessTimeIcon width={22} height={22} />
  },
  {
    title: 'Sales Staff Availability List | 销售人员值班记录',
    path: `./workshop/sale-person-availability`,
    icon: <EventAvailable width={22} height={22} />
  },
  {
    title: 'Update Sales Staff Availability | 更新销售人员值班',
    path: `./workshop/sale-person-availability/new`,
    icon: getIcon(peopleFill)
  }
];

const sidebarFcAppLinks = [
  {
    title: 'admin',
    path: './fc-app/admin',
    icon: <AdminPanelSettingsIcon width={22} height={22} />
  },
  {
    title: 'app mailing list',
    path: './fc-app/app-mailing-list',
    icon: <EmailIcon width={22} height={22} />
  },
  { title: 'app users', path: './fc-app/app-users', icon: getIcon(peopleFill) },
  { title: 'banners', path: './fc-app/banners', icon: getIcon(image2Fill) },
  {
    isUrl: true,
    title: 'chat support',
    path: 'https://freshcarspteltd.myfreshworks.com/crm/messaging/a/703949485055011/inbox/0/0',
    icon: getIcon(messageCircleFill)
  },
  { title: 'news list', path: './fc-app/news-list', icon: getIcon(bookOpenFill) },
  { title: 'notifications', path: './fc-app/notifications', icon: getIcon(bellFill) },
  {
    title: 'promotions',
    path: './fc-app/promotions',
    icon: <LocalFireDepartmentIcon width={22} height={22} />
  },
  {
    isUrl: true,
    title: 'social videos',
    path: 'https://www.youtube.com/playlist?list=PL6JH5-Wkfd5Lz6gsG7j26CLqwuU6-Z_FN',
    icon: getIcon(videoFill)
  },
  { title: 'vouchers', path: './fc-app/vouchers', icon: getIcon(pricetagsFill) }
];

const sidebarFcWebsite = [
  { title: 'promotions', path: './fc-app/fc-website', icon: getIcon(image2Fill) }
];

const sidebarHR = [
  {
    title: 'Leave Summary',
    path: './manager/leave',
    icon: <SummarizeOutlinedIcon width={22} height={22} />
  },
  {
    title: 'Leave Application List',
    path: './manager/leave-user-list',
    icon: <FormatListBulletedIcon width={22} height={22} />
  },
  {
    title: 'Public Holidays',
    path: './manager/public-holiday',
    icon: <EventIcon width={22} height={22} />
  }
];

const sidebarSuperAdmin = [
  {
    title: 'Data Import',
    path: './manager/data-import',
    icon: <DownloadIcon width={22} height={22} />
  },
  { title: 'User List', path: './manager/user-list', icon: getIcon(peopleFill) },
  { title: 'User Logs', path: './manager/user-log', icon: getIcon(peopleFill) }
];

const DRAWER_WIDTH = 300;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[200]
}));

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const authCtx = useContext(AuthContext);

  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (isOpenSidebar) onCloseSidebar();
    if (pathname === '/dashboard/operation/live') setOpen(false);
    else setOpen(true);
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' }
      }}
    >
      <Box sx={{ px: 2.5, py: 3 }}>
        <Box component={RouterLink} to="./" sx={{ display: 'inline-flex' }}>
          <Logo />
        </Box>
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none" component={RouterLink} to="#">
          <AccountStyle>
            <Avatar src={account.photoURL} alt="photoURL" />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {authCtx.name}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {account.role}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box>

      {!['runner', 'towing', 'washer'].includes(authCtx.userDept) && <NavSection navConfig={sidebarConfig} />}

      {/* Sidebar Dropdowns */}

      <SidebarDropdown
        permissions={['sales', 'accounts', 'manager', 'marketing']}
        title="Prices Settings"
        navConfig={sidebarPriceSettings}
      />
      <SidebarDropdown
        permissions={['sales', 'accounts', 'manager', 'marketing']}
        title="Sales"
        navConfig={sidebarSales}
      />
      <SidebarDropdown
        permissions={['accounts', 'manager']}
        title="Accounting"
        navConfig={sidebarAccounting}
      />
      <SidebarDropdown
        permissions={['operation', 'manager']}
        title="Operation"
        navConfig={sidebarOperation}
      />
      <SidebarDropdown
        permissions={['workshop', 'manager']}
        title="Workshop"
        navConfig={sidebarWorkshop}
      />
      <SidebarDropdown
        permissions={['accounts', 'sales', 'marketing', 'operation', 'manager', 'runner', 'towing', 'washer']}
        title="Vehicles"
        navConfig={ ['runner', 'towing', 'washer'].includes(authCtx.userDept) ? sidebarCarParking : sidebarVehicles}
      />
      <SidebarDropdown
        permissions={['accounts', 'marketing', 'manager']}
        title="Fresh Cars | App"
        navConfig={sidebarFcAppLinks}
      />
      <SidebarDropdown
        permissions={['marketing', 'manager']}
        title="Fresh Cars | Website"
        navConfig={sidebarFcWebsite}
      />
      <SidebarDropdown permissions={['manager']} title="HR" navConfig={sidebarHR} />
      <SidebarDropdown permissions={['manager']} title="Admin" navConfig={sidebarSuperAdmin} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <>
      {open && (
        <RootStyle>
          <MHidden width="lgUp">
            <Drawer
              open={isOpenSidebar}
              onClose={onCloseSidebar}
              PaperProps={{
                sx: { width: DRAWER_WIDTH }
              }}
            >
              {renderContent}
            </Drawer>
          </MHidden>

          <MHidden width="lgDown">
            <Drawer
              open
              variant="persistent"
              PaperProps={{
                sx: {
                  width: DRAWER_WIDTH,
                  bgcolor: 'background.default'
                }
              }}
            >
              {renderContent}
            </Drawer>
          </MHidden>
        </RootStyle>
      )}
    </>
  );
}

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};
