import { getAuth } from 'firebase/auth';

const API_BASE_URL = process.env.REACT_APP_ROAD_RUNNER_TIRE_TRACKING_API
const FIREBASE_API = `${API_BASE_URL}/firebase`;

const createAuthHeader = async () => {
  const token = await getAuth().currentUser?.getIdToken();
  if (!token) throw new Error('Authentication required');
  return { Authorization: `Bearer ${token}` };
};

const handleResponse = async (response) => {
  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.error?.message || 'Operation failed');
  }
  return response.json();
};

export const parkingService = {
  getAllCarParks: async () => {
    try {
      const headers = await createAuthHeader();
      const response = await fetch(`${FIREBASE_API}/parking/cars/parks`, {
        headers: {
          ...headers,
          'Content-Type': 'application/json'
        }
      });
      const { data } = await handleResponse(response);
      return data.map((park) => ({
        id: park.id,
        name: park.name,
        address: park.address,
        totalCapacity: park.totalCapacity
      }));
    } catch (error) {
      console.error('Error fetching car parks:', error);
      throw error;
    }
  },

  getAllPlates: async () => {
    try {
      const headers = await createAuthHeader();
      const response = await fetch(`${API_BASE_URL}/car/plates`, {
        headers: {
          ...headers,
          'Content-Type': 'application/json'
        }
      });
      const data = await handleResponse(response);
      return data.data;
    } catch (error) {
      console.error('Error fetching plates:', error);
      throw error;
    }
  },

  getAllCars: async () => {
    try {
      const headers = await createAuthHeader();
      const response = await fetch(`${FIREBASE_API}/parking/cars`, {
        headers: {
          ...headers,
          'Content-Type': 'application/json'
        }
      });
      const { data } = await handleResponse(response);

      return data.map((car) => ({
        id: car.id,
        licensePlate: car.licensePlate,
        section: car.sectionName,
        lotId: car.lotId?.toString(),
        assignTo: car.assignTo,
        remark: car.remark,
        carParkId: car.carParkId,
        userId: car.userId,
        seasonalParking: car.seasonalParking,
        time: car.time,
        carTasks: car.carTasks || [],
        carPark: car.carPark
      }));
    } catch (error) {
      console.error('Error fetching cars:', error);
      throw error;
    }
  },

  addCar: async (carData) => {
    try {
      const headers = await createAuthHeader();
      const username = getAuth().currentUser?.email;

      const request = {
        licensePlate: carData.licensePlate,
        lotId: carData.lotId,
        parkedBy: username,
        seasonalParking: carData.seasonalParking,
        carParkId: carData.carParkId
      };

      const response = await fetch(`${FIREBASE_API}/parking/cars`, {
        method: 'POST',
        headers: {
          ...headers,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(request)
      });

      const { data } = await handleResponse(response);
      return {
        car: {
          id: data.id,
          licensePlate: data.licensePlate,
          section: data.sectionName,
          lotId: data.lotId?.toString(),
          needsTowing: data.needsTowing,
          userId: data.userId,
          seasonalParking: data.seasonalParking,
          time: data.time
        }
      };
    } catch (error) {
      console.error('Error adding car:', error);
      throw error;
    }
  },

  exitCarFromParking: async (licensePlate) => {
    try {
      const headers = await createAuthHeader();
      const username = getAuth().currentUser?.email;

      const response = await fetch(`${FIREBASE_API}/parking/cars/exit`, {
        method: 'POST',
        headers: {
          ...headers,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          licensePlate,
          exitByUserId: username
        })
      });

      const { data } = await handleResponse(response);
      return data;
    } catch (error) {
      console.error('Error exiting car:', error);
      throw error;
    }
  },

  updateCar: async (carId, updateData) => {
    try {
      const username = getAuth().currentUser?.email;
      updateData = { ...updateData, userId: username };
      const headers = await createAuthHeader();
      const response = await fetch(`${FIREBASE_API}/parking/cars`, {
        method: 'PUT',
        headers: {
          ...headers,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(updateData)
      });

      const { data } = await handleResponse(response);
      return data;
    } catch (error) {
      console.error('Error updating car:', error);
      throw error;
    }
  }
};
