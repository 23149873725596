import React, { memo, useCallback } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';

export const PaginatedContent = memo(({ 
  items, 
  page, 
  setPage, 
  rowsPerPage, 
  setRowsPerPage, 
  totalLabel = "items", 
  renderContent 
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handlePageChange = useCallback((_event, newPage) => {
    setPage(newPage);
  }, [setPage]);

  const handleRowsPerPageChange = useCallback((event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(1);
  }, [setRowsPerPage, setPage]);

  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedItems = items.slice(startIndex, endIndex);
  const totalPages = Math.ceil(items.length / rowsPerPage);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {renderContent(paginatedItems)}

      <Stack 
        direction={isMobile ? 'column' : 'row'}
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        sx={{ 
          width: '100%', 
          flexWrap: 'wrap',
          gap: isMobile ? 1 : 0 
        }}
      >
        <FormControl 
          size={isMobile ? 'small' : 'medium'} 
          sx={{ minWidth: 120 }}
        >
          <InputLabel>Rows per page</InputLabel>
          <Select
            value={rowsPerPage.toString()}
            label="Rows per page"
            onChange={handleRowsPerPageChange}
          >
            {[5, 10, 25, 50].map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Typography variant={isMobile ? 'body2' : 'body1'} sx={{ textAlign: 'center' }}>
          {items.length > 0 
            ? `${Math.min(startIndex + 1, items.length)}-${Math.min(endIndex, items.length)} of ${items.length} ${totalLabel}`
            : `No ${totalLabel} available`
          }
        </Typography>

        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          color="primary"
          size={isMobile ? 'small' : 'medium'}
          showFirstButton
          showLastButton
        />
      </Stack>
    </Box>
  );
});

export const usePagination = (initialRowsPerPage = 10) => {
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(initialRowsPerPage);

  return { 
    page, 
    setPage, 
    rowsPerPage, 
    setRowsPerPage 
  };
};

export default PaginatedContent;