import { useCallback, useEffect, useReducer, useRef } from 'react';
import { parkingService } from '../../services/parkingService';

const initialState = {
  cars: [],
  filteredCars: [],
  carParks: [],
  isLoading: false,
  error: null,
  snackbar: {
    open: false,
    message: '',
    severity: 'success'
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_LOADING':
      return { ...state, isLoading: action.payload };
    case 'SET_CARS':
      return { 
        ...state, 
        cars: action.payload,
        filteredCars: action.payload,
        isLoading: false 
      };
    case 'SET_CAR_PARKS':
      return { 
        ...state, 
        carParks: action.payload,
        isLoadingParks: false 
      };
    case 'SET_FILTERED_CARS':
      return { ...state, filteredCars: action.payload };
    case 'SET_ERROR':
      return { 
        ...state, 
        error: action.payload,
        isLoading: false,
        snackbar: {
          open: true,
          message: action.payload,
          severity: 'error'
        }
      };
    case 'SET_SNACKBAR':
      return { ...state, snackbar: action.payload };
    default:
      return state;
  }
};

export const useParking = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const mounted = useRef(true);

  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  const fetchCarParks = useCallback(async () => {
    if (!mounted.current) return;
    dispatch({ type: 'SET_LOADING_PARKS', payload: true });
    
    try {
      const data = await parkingService.getAllCarParks();
      if (mounted.current) {
        dispatch({ type: 'SET_CAR_PARKS', payload: data });
      }
    } catch (err) {
      if (mounted.current) {
        dispatch({ type: 'SET_ERROR', payload: err.message });
      }
    }
  }, []);

  const fetchCars = useCallback(async () => {
    if (!mounted.current) return;
    dispatch({ type: 'SET_LOADING', payload: true });
    
    try {
      const data = await parkingService.getAllCars();
      if (mounted.current) {
        dispatch({ type: 'SET_CARS', payload: data });
      }
    } catch (err) {
      if (mounted.current) {
        dispatch({ type: 'SET_ERROR', payload: err.message });
      }
    }
  }, []);

  const handleSearch = useCallback(({ searchText, filters }) => {
    let filtered = [...state.cars];

    if (searchText) {
      const searchLower = searchText.toLowerCase();
      filtered = filtered.filter(car => 
        car.licensePlate.toLowerCase().includes(searchLower) ||
        car.lotId?.toString().includes(searchLower) ||
        car.userId?.toLowerCase().includes(searchLower)
      );
    }

    if (filters.parking) {
      filtered = filtered.filter(car => car.carPark.name === filters.parking);
    }

    if (filters.seasonalParking) {
      filtered = filtered.filter(car => car.seasonalParking === filters.seasonalParking);
    }

    dispatch({ type: 'SET_FILTERED_CARS', payload: filtered });
  }, [state.cars]);

  const handleUpdateCar = useCallback(async (carId, updateData) => {
    if (!mounted.current) return;
    dispatch({ type: 'SET_LOADING', payload: true });

    try {
      const updatedCar = await parkingService.updateCar(carId, updateData);
      if (mounted.current) {
        const updatedCars = state.cars.map(car => 
          car.id === carId ? { ...car, ...updatedCar } : car
        );
        dispatch({ type: 'SET_CARS', payload: updatedCars });
        dispatch({ 
          type: 'SET_SNACKBAR', 
          payload: {
            open: true,
            message: 'Car updated successfully',
            severity: 'success'
          }
        });
      }
    } catch (err) {
      if (mounted.current) {
        dispatch({ type: 'SET_ERROR', payload: err.message });
      }
    }
  }, [state.cars]);

  const handleDriveOut = useCallback(async (licensePlate) => {
    if (!mounted.current) return;
    dispatch({ type: 'SET_LOADING', payload: true });

    try {
      await parkingService.exitCarFromParking(licensePlate);
      if (mounted.current) {
        const updatedCars = state.cars.filter(car => car.licensePlate !== licensePlate);
        dispatch({ type: 'SET_CARS', payload: updatedCars });
        dispatch({ 
          type: 'SET_SNACKBAR', 
          payload: {
            open: true,
            message: 'Car successfully driven out',
            severity: 'success'
          }
        });
      }
    } catch (err) {
      if (mounted.current) {
        dispatch({ type: 'SET_ERROR', payload: err.message });
      }
    }
  }, [state.cars]);

  const openSnackbar = useCallback(({message, severity}) => {
    dispatch({ 
      type: 'SET_SNACKBAR', 
      payload: { open: true, message, severity }
    });
  }, []);
  const closeSnackbar = useCallback(() => {
    dispatch({ 
      type: 'SET_SNACKBAR', 
      payload: { ...state.snackbar, open: false }
    });
  }, [state.snackbar]);

  useEffect(() => {
    fetchCars();
    fetchCarParks();
  }, [fetchCars, fetchCarParks]);

  return {
    ...state,
    handleSearch,
    openSnackbar,
    handleUpdateCar,
    handleDriveOut,
    closeSnackbar,
    refreshCars: fetchCars
  };
};