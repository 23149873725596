import { Clear, FilterList, Search } from '@mui/icons-material';
import {
  Box,
  Chip,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Popover,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { memo, useCallback, useState } from 'react';

const SEASONAL_PARKING = ['Yes', 'No', "Unknown"];

export const ParkingSearch = memo(({ onSearch, carParks }) => {
  const [searchText, setSearchText] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [filters, setFilters] = useState({
    parking: '',
    seasonalParking: ''
  });

  const handleFilterClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCloseFilters = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleFilterChange = useCallback((field, value) => {
    const newFilters = { ...filters, [field]: value };
    setFilters(newFilters);
    onSearch({ searchText, filters: newFilters });
  }, [filters, searchText, onSearch]);

  const clearFilters = useCallback(() => {
    const clearedFilters = {
      parking: '',
      seasonalParking: ''
    };
    setFilters(clearedFilters);
    onSearch({ searchText, filters: clearedFilters });
  }, [searchText, onSearch]);

  const handleSearchChange = useCallback((event) => {
    const text = event.target.value;
    setSearchText(text);
    onSearch({ searchText: text, filters });
  }, [filters, onSearch]);

  const clearSearch = useCallback(() => {
    setSearchText('');
    onSearch({ searchText: '', filters });
  }, [filters, onSearch]);

  const activeFilterCount = Object.values(filters).filter(Boolean).length;

  return (
    <Box sx={{ mb: 2 }}>
      <TextField
        fullWidth
        placeholder="Search by ID, plate number, lot, remarks, or parked by..."
        value={searchText}
        onChange={handleSearchChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <Stack direction="row" spacing={1}>
                {searchText && (
                  <IconButton size="small" onClick={clearSearch}>
                    <Clear />
                  </IconButton>
                )}
                <IconButton onClick={handleFilterClick} color={activeFilterCount > 0 ? "primary" : "default"}>
                  <FilterList />
                </IconButton>
                {activeFilterCount > 0 && (
                  <Chip 
                    size="small" 
                    label={activeFilterCount} 
                    color="primary"
                  />
                )}
              </Stack>
            </InputAdornment>
          )
        }}
        sx={{ bgcolor: 'background.paper' }}
      />

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleCloseFilters}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Paper sx={{ p: 2, width: 300 }}>
          <Stack spacing={2}>
            <Typography variant="subtitle2" gutterBottom>
              Filters
            </Typography>

            <FormControl fullWidth size="small">
              <InputLabel>Section</InputLabel>
              <Select
                value={filters.parking}
                label="Section"
                onChange={(e) => handleFilterChange('parking', e.target.value)}
              >
                <MenuItem value="">All</MenuItem>
                {carParks.map((parking) => (
                  <MenuItem key={parking.id} value={parking.name}>
                    {parking.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth size="small">
              <InputLabel>Season Parking</InputLabel>
              <Select
                value={filters.seasonalParking}
                label="Season Parking"
                onChange={(e) => handleFilterChange('seasonalParking', e.target.value)}
              >
                <MenuItem value="">All</MenuItem>
                {SEASONAL_PARKING.map((status) => (
                  <MenuItem key={status} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {activeFilterCount > 0 && (
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Chip 
                  label="Clear Filters" 
                  onDelete={clearFilters}
                  size="small"
                />
              </Box>
            )}
          </Stack>
        </Paper>
      </Popover>
    </Box>
  );
});

export default ParkingSearch;