import CloseIcon from '@mui/icons-material/Close';
import { AppBar, Box, Dialog, IconButton, Slide, Toolbar, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import * as React from 'react';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function NormalDialog(props) {
  const handleClose = () => {
    props.handleDialogClose();
  };

  NormalDialog.propTypes = {
    code: PropTypes.func,
    data: PropTypes.object,
    dialogOpen: PropTypes.bool,
    handleDialogClose: PropTypes.func
  };

  return (
    <>
      <Dialog
        open={props.dialogOpen}
        onClose={props.handleDialogClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'sticky' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {`${props.data.hirer} | ${props.data.docType}`}
              {props.data.editMode ? ` ${props.data.docNo}` : ' '}
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ p: 2 }}>{props.code()}</Box>
      </Dialog>
    </>
  );
}
