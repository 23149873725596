import { DirectionsCar, Edit, ExitToApp, ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import dayjs from 'dayjs';
import { memo, useCallback } from 'react';
import { TeamToggleButtons } from './TeamToggleButtons';

const CarItem = memo(({ 
  car, 
  isExpanded, 
  onExpand, 
  onEdit, 
  onDriveOut,
  userDept,
  view,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleExpand = useCallback(() => {
    onExpand(car.id);
  }, [car.id, onExpand]);

  const isOperationalUser = userDept === 'superadmin' || userDept === 'operation';
  const showOperationalControls = isOperationalUser && view === 'operations';

  const renderActions = () => {
    if (isMobile) {
      return (
        <Stack spacing={1} width="100%">
          <ButtonGroup size="small" variant="outlined" fullWidth>
            <Button
              onClick={handleExpand}
              startIcon={isExpanded ? <ExpandLess /> : <ExpandMore />}
            >
              {isExpanded ? 'Hide' : 'Map'}
            </Button>
            {showOperationalControls && (
              <Button onClick={() => onEdit(car)} startIcon={<Edit />}>
                Edit
              </Button>
            )}
            <Button onClick={() => onDriveOut(car)} startIcon={<ExitToApp />}>
              Exit
            </Button>
          </ButtonGroup>
        </Stack>
      );
    }

    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <ButtonGroup size="small" variant="outlined">
          {showOperationalControls && (
            <TeamToggleButtons 
              car={car} 
              currentTask={car.carTasks}
            />
          )}

          {showOperationalControls && (
            <Tooltip title="Edit">
              <IconButton size="small" onClick={() => onEdit(car)}>
                <Edit fontSize="small" />
              </IconButton>
            </Tooltip>
          )}

          <Tooltip title="Drive Out">
            <IconButton size="small" onClick={() => onDriveOut(car)}>
              <ExitToApp fontSize="small" />
            </IconButton>
          </Tooltip>
        </ButtonGroup>
      </Box>
    );
  };

  return (
    <Paper
      elevation={1}
      sx={{
        p: 1.5,
        position: 'relative',
        '&:hover': { bgcolor: 'action.hover' }
      }}
    >
      <Stack spacing={1.5}>
        <Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              mb: 1,
              flexWrap: 'wrap'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                minWidth: isMobile ? '100%' : 'auto'
              }}
            >
              <DirectionsCar fontSize="small" />
              <Typography variant="subtitle2">{car.licensePlate}</Typography>
            </Box>
            {car.lotId && (
              <Typography variant="body2" color="textSecondary" sx={{ flexGrow: 1 }}>
                Section {car.section} Lot {car.lotId} At {car.carPark?.name}
              </Typography>
            )}
          </Box>

          <Stack 
            direction={isMobile ? 'column' : 'row'} 
            spacing={1} 
            sx={{ mb: 1 }}
          >
            <Chip
              size="small"
              label={`by ${car.userId} at ${dayjs(car.time).format('DD MMM YYYY, HH:mm')}`}
              variant="outlined"
              sx={{ maxWidth: '100%' }}
            />
            {car.seasonalParking && (
              <Chip
                size="small"
                label={`Season: ${car.seasonalParking}`}
                color={
                  car.seasonalParking === 'Yes'
                    ? 'success'
                    : car.seasonalParking === 'No'
                    ? 'error'
                    : 'default'
                }
              />
            )}
          </Stack>
        </Box>

        {renderActions()}
      </Stack>
    </Paper>
  );
});

export default CarItem;