import { useContext } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
import AgreementListing from './pages/Accounting/AgreementListing';
import BulkApplyCollection from "./pages/Accounting/BulkApplyCollection";
import TransactionsListing from './pages/Accounting/Transactions/TransactionsListing';
import UserWallet from "./pages/Accounting/UserWallet";
import Blog from './pages/Blog';
import BookingDetails from './pages/Booking/BookingDetails';
import BookingList from './pages/Booking/BookingList';
import RentalAgreementPreview from './pages/Booking/Documents/RentalAgreementPreview';
import EditBooking from './pages/Booking/EditBooking';
import NewBooking from './pages/Booking/NewBooking';
import PriceSettings from './pages/Booking/PriceSettings';
import SurchargesList from './pages/Booking/PriceSettings/SurchargesList';
import RenewBooking from './pages/Booking/RenewBooking';
import Cars from './pages/Cars';
import DashboardApp, { routeName as dashboardAppRouteName } from './pages/DashboardApp';
import FcAppAdmin from './pages/FcApp/FcAppAdmin';
import FcAppNotifications from './pages/FcApp/FcAppNotifications';
import EcommerceShop from './pages/FcAppCarModels';
import FcLeaveCalendar from './pages/Leave/FcLeaveCalendar';
import PublicHoliday from './pages/Leave/manager/PublicHoliday';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import CustomerList from './pages/Sales/Customer/CustomerList';
import OtrListing from "./pages/Sales/OTR/OtrListing";
import ShellCardListing from './pages/ShellCard/ShellCard';
import UserLogs from "./pages/SuperAdmin/UserLogs";
import DisposeCheckList from './pages/Vehicle/Disposal/DisposeCheckList';
import NewVehicle from './pages/Vehicle/NewVehicle';
import EditVehiclePricingTable from './pages/Vehicle/Pricing/EditVehiclePricingTable';
import PriceTableColumns from './pages/Vehicle/Pricing/PriceTableColumns';
import VehiclePricingTable from './pages/Vehicle/Pricing/VehiclePricingTable';
import VehicleSettings from './pages/Vehicle/VehicleSettings';
import VehicleUsageRecord from './pages/Vehicle/VehicleUsageRecord';

import AccountsSettings from './pages/Accounting/AccountsSettings';
import BookingsAccounts from './pages/Accounting/BookingsAccounts';
import CreditNoteListing from './pages/Accounting/CreditNoteListing';
import InvoiceListing from './pages/Accounting/InvoiceListing';
import PaymentVouchers from './pages/Accounting/PaymentVouchers';
import RefundVouchers from './pages/Accounting/RefundVouchers';
import Reports from './pages/Accounting/Reports';
import CarDetail from './pages/Cars/CarDetail';
import CarsMain from './pages/Cars/CarsMain';
import ChangePassword from './pages/ChangePassword';
import Chat from './pages/Chat';
import DataImport from './pages/DataImport/DataImport';
import ChatDrawer from './pages/FcApp/ChatDrawer';
import FcAppBanners from './pages/FcApp/FcAppBanners';
import CarModelsMain, { routeName as carModelsMainRouteName } from './pages/FcApp/FcAppCarModelsMain';
import FcAppMailingList from './pages/FcApp/FcAppMailingList';
import FcAppNewCarModel from './pages/FcApp/FcAppNewCarModel';
import FcAppPromotions from './pages/FcApp/FcAppPromotions';
import FcAppUsers from './pages/FcApp/FcAppUsers';
import FcAppVouchers from './pages/FcApp/FcAppVouchers';
import NewsList from './pages/FcApp/NewsList';
import FcWebsitePromotions from './pages/FcWebsite/FcWebsitePromotions';
import LeaveApplicationList from './pages/Leave/manager/LeaveApplicationList';
import LeaveSummaryList from './pages/Leave/manager/LeaveSummaryList';
import UserLeaveList from './pages/Leave/UserLeaveList';
import LiveDashboard from './pages/Operation/LiveDashboard/LiveDashboard';
import OperationSettings from './pages/Operation/OperationSettings';
import SalePersonAvailabilityList from './pages/Operation/SalePersonAvailabilityList';
import TireServiceListing from './pages/Operation/TireServiceListing';
import ViewJobAdmin from './pages/Operation/ViewJobAdmin';
import EditWorkshopRequest from './pages/Operation/WorkshopRequest/EditWorkshopRequest';
import NewWorkshopRequest from './pages/Operation/WorkshopRequest/NewWorkshopRequest';
import SalePersonAvailability from './pages/Operation/WorkshopRequest/TrackSalePerson';
import WorkshopRequestListing from './pages/Operation/WorkshopRequest/WorkshopRequestListing';
import EditWorkshopTask from './pages/Operation/WorkshopTask/EditWorkshopTask';
import NewWorkshopTask from './pages/Operation/WorkshopTask/NewWorkshopTask';
import WorkshopTaskListing from './pages/Operation/WorkshopTask/WorkshopTaskListing';
import EditWorkshopVehicle from './pages/Operation/WorkshopVehicle/EditWorkshopVehicle';
import NewWorkshopVehicle from './pages/Operation/WorkshopVehicle/NewWorkshopVehicle';
import WorkshopVehicleListing from './pages/Operation/WorkshopVehicle/WorkshopVehicleListing';
import Profile from './pages/Profile';
import JobListing from './pages/Sales/JobListing';
import Otr from './pages/Sales/Otr';
import EditReturnJob from './pages/Sales/ReturnJob/EditReturnJob';
import NewReturnJob from './pages/Sales/ReturnJob/NewReturnJob';
import ReturnJobListing from './pages/Sales/ReturnJob/ReturnJobListing';
import EditWorkshopAppointment from './pages/Sales/WorkshopAppointment/EditWorkshopAppointment';
import NewWorkshopAppointment from './pages/Sales/WorkshopAppointment/NewWorkshopAppointment';
import WorkshopAppointmentListing from './pages/Sales/WorkshopAppointment/WorkshopAppointmentListing';
import UsersList from './pages/SuperAdmin/UsersList';
import EditDisposalStatus from './pages/Vehicle/Disposal/EditDisposalStatus';
import NewDisposalStatus from './pages/Vehicle/Disposal/NewDisposalStatus';
import NewDisposalTask from './pages/Vehicle/Disposal/NewDisposalTask';
import VehicleDisposalCheckList from './pages/Vehicle/Disposal/VehicleDisposalCheckList';
import VehicleDisposalStatus from './pages/Vehicle/Disposal/VehicleDisposalStatus';
import VehicleDisposalTask from './pages/Vehicle/Disposal/VehicleDisposalTask';
import EditVehicle from './pages/Vehicle/EditVehicle';
import ParkingManagement from './pages/Vehicle/ParkingManagement';
import VehicleAvailableListing from './pages/Vehicle/VehicleAvailableListing';
import VehicleDetails from './pages/Vehicle/VehicleDetails';
import VehicleDisposingListing from './pages/Vehicle/VehicleDisposingListing';
import VehicleInsepctionListing from './pages/Vehicle/VehicleInspectionListing';
import VehicleListing from './pages/Vehicle/VehicleListing';
import VehicleLogcardInsuranceUpsert from './pages/Vehicle/VehicleLogcardInsuranceUpsert';
import AppointmentListing from './pages/Workshop/AppointmentListing';
import EditJob from './pages/Workshop/Job/EditJob';
import NewJob from './pages/Workshop/Job/NewJob';
import ViewJobWorkshop from './pages/Workshop/Job/ViewJobWorkshop';
import ViewAssignedJobWorkshop from './pages/Workshop/ViewAssignedJobWorkshop';
import AuthContext from './store/AuthContext';

const salesRoutes = {
  path: 'sales',
  children: [
    { path: 'otr', element: <Otr /> },
    { path: 'new-otr', element: <OtrListing /> },
    {
      path: 'booking',
      children: [
        { index: true, element: <BookingList /> },
        { path: 'new', element: <NewBooking /> },
        {
          path: ':bookingNo',
          children: [
            { index: true, element: <BookingDetails /> },
            { path: 'edit', element: <EditBooking /> },
            { path: 'renew', element: <RenewBooking /> },
          ],
        },
      ],
    },
    { path: 'booking-chart', element: <VehicleUsageRecord /> },
    // { path: 'booking-chart', element: <TestVirtualization /> },
    {
      path: 'customer',
      children: [{ index: true, element: <CustomerList /> }],
    },
    { path: 'workshop-jobs', element: <JobListing /> },
    {
      path: 'workshop-return-jobs',
      children: [
        { index: true, element: <ReturnJobListing /> },
        { path: 'new', element: <NewReturnJob /> },
        { path: 'edit/:id', element: <EditReturnJob /> },
      ]
    },
    {
      path: 'workshop-appointment',
      children: [
        { index: true, element: <WorkshopAppointmentListing /> },
        { path: 'new', element: <NewWorkshopAppointment /> },
        { path: 'edit/:id', element: <EditWorkshopAppointment /> },
      ],
    },
  ],
};

const priceRoutes = {
  path: 'booking',
  children: [
    {
      path: 'price',
      children: [
        {
          path: 'rental',
          children: [
            { index: true, element: <VehiclePricingTable /> },
            { path: 'edit', element: <EditVehiclePricingTable /> },
            { path: 'period', element: <PriceTableColumns /> },
          ],
        },
        { path: 'settings', element: <PriceSettings /> },
      ]
    },
    {
      path: 'surcharges',
      children: [{ index: true, element: <SurchargesList /> }],
    },
    {
      path: 'rental-agreement',
      children: [{ path: 'preview', element: <RentalAgreementPreview /> }],
    }
  ],
};

const accountsRoutes = {
  path: 'accounting',
  children: [
    { path: 'agreements', element: <AgreementListing /> },
    { path: 'account-reports', element: <TransactionsListing /> },
    { path: 'user-wallet', element: <UserWallet /> },
    { path: 'bookings-accounts', element: <BookingsAccounts /> },
    { path: 'invoice-listing', element: <InvoiceListing /> },
    { path: 'credit-note-listing', element: <CreditNoteListing /> },
    { path: 'reports', element: <Reports /> },
    { path: 'payment-vouchers', element: <PaymentVouchers /> },
    { path: 'refund-vouchers', element: <RefundVouchers /> },
    { path: 'accounts-settings', element: <AccountsSettings /> },
    { path: 'shell-card', element: <ShellCardListing /> },
    { path: 'bulk-apply', element: <BulkApplyCollection /> },
  ],
};

const marketingRoutes = {
  path: 'fc-app',
  children: [
    { path: 'app-mailing-list', element: <FcAppMailingList /> },
    { path: 'app-users', element: <FcAppUsers /> },
    { path: 'admin', element: <FcAppAdmin /> },
    { path: 'banners', element: <FcAppBanners /> },
    {
      path: 'chat-support',
      element: <Chat />,
      children: [{ index: true, element: <ChatDrawer /> }]
    },
    { path: 'news-list', element: <NewsList /> },
    { path: 'notifications', element: <FcAppNotifications /> },
    { path: 'promotions', element: <FcAppPromotions /> },
    { path: 'vouchers', element: <FcAppVouchers /> },
    {
      children: [
        {
          path: 'fc-website',
          children: [
            { index: true, element: <FcWebsitePromotions /> }
          ],
        },
      ],
    },
  ],
};

const operationRoutes = {
  path: 'operation',
  children: [
    {
      path: 'vehicle',
      children: [
        { index: true, element: <WorkshopVehicleListing /> },
        { path: 'new', element: <NewWorkshopVehicle /> },
        { path: 'edit/:vehicleNumber', element: <EditWorkshopVehicle /> },
      ],
    },
    {
      path: 'task',
      children: [
        { index: true, element: <WorkshopTaskListing /> },
        { path: 'new', element: <NewWorkshopTask /> },
        { path: 'edit/:id', element: <EditWorkshopTask /> },
      ],
    },
    {
      path: 'workshop-appointment',
      children: [
        { index: true, element: <WorkshopAppointmentListing /> },
        { path: 'new', element: <NewWorkshopAppointment /> },
        { path: 'edit/:id', element: <EditWorkshopAppointment /> },
      ],
    },
    {
      path: 'workshop-request',
      children: [
        { index: true, element: <WorkshopRequestListing /> },
        { path: 'new', element: <NewWorkshopRequest /> },
        { path: 'edit/:id', element: <EditWorkshopRequest /> },
      ],
    },
    { path: 'workshop', element: <ViewJobAdmin /> },
    { path: 'live', element: <LiveDashboard /> },
    { path: '3rd-party-tracking', element: <TireServiceListing /> },
    { path: 'settings', element: <OperationSettings /> },
  ],
};

const workshopRoutes = {
  path: 'workshop',
  children: [
    {
      path: 'job',
      children: [
        { index: true, element: <ViewJobWorkshop /> },
        { path: 'new', element: <NewJob /> },
        { path: 'edit/:id', element: <EditJob /> },
        { path: 'assigned', element: <ViewAssignedJobWorkshop /> },
      ],
    },
    {
      path: 'appointment',
      children: [
        { index: true, element: <AppointmentListing /> },
      ],
    },
    { path: 'sale-person-availability',
      children: [
        {index: true, element: <SalePersonAvailabilityList />},
        {path: 'new', element: <SalePersonAvailability />}
      ]
    },
  ],
};

const managerRoutes = {
  path: 'manager',
  children: [
    {
      path: 'leave',
      children: [{ index: true, element: <LeaveSummaryList /> }]
    },
    { path: 'leave-user-list', element: <LeaveApplicationList /> },
    { path: 'public-holiday', element: <PublicHoliday /> },
    { path: 'user-list', element: <UsersList /> },
    { path: 'user-log', element: <UserLogs /> },
    { path: 'data-import', element: <DataImport /> }
  ]
};

export default function Router() {
  const authCtx = useContext(AuthContext);
  const { userDept } = authCtx;

  let appRoutes = [
    {
      path: '/',
      element: <Navigate to="/login" replace />,
      children: [
        { path: 'register', element: <Register /> },
        { path: '*', element: <Navigate to="/login" /> }
      ]
    },
    {
      path: 'login',
      element: <Login />
    }
  ];

  if (authCtx.isLoggedIn) {
    appRoutes = [
      { path: '/', element: <Navigate to="./dashboard/app" replace /> },
      { path: '/404', element: <NotFound /> },
      {
        path: `/${dashboardAppRouteName}`,
        element: <DashboardLayout />,
        children: [
          { path: '', element: <Navigate to="./app" replace /> },
          { path: 'profile', element: <Profile /> },
          { path: 'changePwd', element: <ChangePassword /> },
          { path: 'app', element: <DashboardApp /> },
          {
            path: 'leave',
            children: [
              { index: true, element: <UserLeaveList /> },
              { path: 'calendar', element: <FcLeaveCalendar /> }
            ]
          },
          {
            path: 'cars',
            element: <Cars />,
            children: [
              { index: true, element: <CarsMain /> },
              { path: ':carPlate', element: <CarDetail /> }
            ]
          },
          {
            path: 'vehicles',
            children: [
              { index: true, element: <VehicleListing /> },
              { path: 'upsert', element: <VehicleLogcardInsuranceUpsert /> },
              { path: 'new', element: <NewVehicle /> },
              { path: 'settings', element: <VehicleSettings /> },
              { path: 'available', element: <VehicleAvailableListing /> },
              { path: 'disposing', element: <VehicleDisposingListing /> },
              {
                path: ':id',
                children: [
                  { index: true, element: <VehicleDetails /> },
                  { path: 'edit', element: <EditVehicle /> },
                  { path: 'dispose', element: <DisposeCheckList /> }
                ]
              },
              { path: 'inspection', element: <VehicleInsepctionListing /> },
              {
                path: 'disposal',
                children: [
                  {
                    path: 'tasks',
                    children: [
                      { index: true, element: <VehicleDisposalTask /> },
                      { path: 'new', element: <NewDisposalTask /> }
                    ]
                  },
                  {
                    path: 'status',
                    children: [
                      { index: true, element: <VehicleDisposalStatus /> },
                      { path: 'new', element: <NewDisposalStatus /> },
                      {
                        path: 'edit/:id',
                        children: [
                          { index: true, element: <EditDisposalStatus /> },
                          { path: 'new', element: <NewDisposalTask /> }
                        ]
                      }
                    ]
                  },
                  { path: ':vehicle', element: <VehicleDisposalCheckList /> }
                ]
              },
              { path: 'car-parking', element: <ParkingManagement /> },
            ]
          },
          {
            path: carModelsMainRouteName,
            element: <EcommerceShop />,
            children: [
              { index: true, element: <CarModelsMain /> },
              {
                path: ':carModel',
                element: <FcAppNewCarModel />
              }
            ]
          },
          { path: 'blog', element: <Blog /> },
          { path: '*', element: <Navigate to="/404" /> }
        ]
      },
      { path: '*', element: <Navigate to="./" /> }
    ];

    const dashboardIndex = appRoutes.findIndex((el) => el.path === `/${dashboardAppRouteName}`);

    switch (userDept) {
      case 'superadmin':
        appRoutes[dashboardIndex].children = [
          ...appRoutes[dashboardIndex].children,
          salesRoutes,
          priceRoutes,
          accountsRoutes,
          managerRoutes,
          marketingRoutes,
          operationRoutes,
          workshopRoutes
        ];
        break;
      case 'marketing':
        appRoutes[dashboardIndex].children = [
          salesRoutes,
          ...appRoutes[dashboardIndex].children,
          marketingRoutes,
          priceRoutes
        ];
        break;
      case 'manager':
        appRoutes[dashboardIndex].children = [
          ...appRoutes[dashboardIndex].children,
          salesRoutes,
          priceRoutes,
          accountsRoutes,
          managerRoutes,
          marketingRoutes,
          operationRoutes,
        ];
        break;
      case 'sales':
        appRoutes[dashboardIndex].children = [
          ...appRoutes[dashboardIndex].children,
          salesRoutes,
          priceRoutes
        ];
        break;
      case 'accounts':
        appRoutes[dashboardIndex].children = [
          ...appRoutes[dashboardIndex].children,
          salesRoutes,
          priceRoutes,
          accountsRoutes
        ];
        break;
      case 'operation':
        appRoutes[dashboardIndex].children = [
          ...appRoutes[dashboardIndex].children,
          operationRoutes
        ];
        break;
      case 'workshop':
        appRoutes[dashboardIndex].children = [
          ...appRoutes[dashboardIndex].children,
          workshopRoutes
        ];
        break;
      case 'runner':
        appRoutes[dashboardIndex].children = [
          ...appRoutes[dashboardIndex].children,
          workshopRoutes
        ];
        break;
      case 'towing':
        appRoutes[dashboardIndex].children = [
          ...appRoutes[dashboardIndex].children,
          workshopRoutes
        ];
        break;
      case 'washer':
        appRoutes[dashboardIndex].children = [
          ...appRoutes[dashboardIndex].children,
          workshopRoutes
        ];
        break;
      default:
        break;
    }
  }

  return useRoutes(appRoutes);
}
