import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { parkingService } from '../services/parkingService';

const CarPlateAutocomplete = ({ value, onChange }) => {
  const [plates, setPlates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [inputValue, setInputValue] = useState(value || '');

  useEffect(() => {
    const fetchPlates = async () => {
      try {
        const data = await parkingService.getAllPlates();
        setPlates(data.map(p => p.plateNumber));
      } catch (error) {
        console.error('Failed to fetch plates:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPlates();
  }, []);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleInputChange = (event, newInputValue) => {
    const upperValue = newInputValue?.toUpperCase() || '';
    setInputValue(upperValue);
    onChange(upperValue);
  };

  const handleChange = (event, newValue) => {
    onChange(newValue || '');
  };

  return (
    <Autocomplete
      value={value}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      options={plates}
      loading={loading}
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          label="Car Plate Number"
          required
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default CarPlateAutocomplete;