import { getAuth } from 'firebase/auth';


const API_BASE_URL = `${process.env.REACT_APP_ROAD_RUNNER_TIRE_TRACKING_API}/firebase`;

const createAuthHeader = async () => {
  const token = await getAuth().currentUser?.getIdToken();
  if (!token) throw new Error('Authentication required');
  return { Authorization: `Bearer ${token}` };
};

const handleResponse = async (response) => {
  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.error?.message || 'Operation failed');
  }
  return response.json();
};

export const carTaskService = {
  createTask: async (taskData) => {
    try {
      const headers = await createAuthHeader();
      const response = await fetch(`${API_BASE_URL}/CarTask`, {
        method: 'POST',
        headers: {
          ...headers,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(taskData)
      });
      return handleResponse(response);
    } catch (error) {
      console.error('Error creating task:', error);
      throw error;
    }
  },

  deleteTask: async (taskId) => {
    try {
      const headers = await createAuthHeader();
      await fetch(`${API_BASE_URL}/CarTask/${taskId}`, {
        method: 'DELETE',
        headers: headers
      });
      return true;
    } catch (error) {
      console.error('Error deleting task:', error);
      throw error;
    }
  },

  getCarTasks: async (assignTo) => {
    try {
      const headers = await createAuthHeader();
      const response = await fetch(`${API_BASE_URL}/Parking/cars/assign/${assignTo}`, {
        headers: headers
      });
      return handleResponse(response);
    } catch (error) {
      console.error('Error fetching tasks:', error);
      throw error;
    }
  },

  updateTaskStatus: async (taskId, task) => {
    try {
      const headers = await createAuthHeader();
      const response = await fetch(`${API_BASE_URL}/CarTask/${taskId}`, {
        method: 'PUT',
        headers: {
          ...headers,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(task)
      });
      return handleResponse(response);
    } catch (error) {
      console.error('Error updating task status:', error);
      throw error;
    }
  }
};