export const getSectionFromLot = (lot) => {
  const lotNum = parseInt(lot, 10);
  if (lotNum >= 863 && lotNum <= 895) return 'A';
  if (lotNum >= 886 && lotNum <= 946) return 'B';
  if (lotNum >= 531 && lotNum <= 549) return 'C';
  if (lotNum >= 512 && lotNum <= 523) return 'D';
  if (lotNum >= 507 && lotNum <= 511) return 'E';
  if (lotNum >= 307 && lotNum <= 326) return 'F';
  if (lotNum >= 327 && lotNum <= 435) return 'G';
  if (lotNum >= 192 && lotNum <= 277) return 'H';
  return null;
};

// Function to get section boundaries for zooming
export const SECTION_BOUNDS = {
  A: { x: 685, y: 220, width: 400, height: 100, scale: 2 },
  B: { x: 285, y: 220, width: 600, height: 100, scale: 2 },
  C: { x: 320, y: 80, width: 100, height: 240, scale: 2.5 },
  D: { x: 185, y: 230, width: 108, height: 50, scale: 3 },
  E: { x: 240, y: 400, width: 150, height: 50, scale: 2.5 },
  F: { x: 400, y: 450, width: 600, height: 50, scale: 2 },
  G: { x: 200, y: 550, width: 981, height: 50, scale: 2 },
  H: { x: 200, y: 650, width: 774, height: 50, scale: 2 }
};

export const PARKING_CONSTANTS = {
  SECTIONS: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'],
  ASSIGN_TO: ['Towing', 'Runner', 'Washer'],
  SEASONAL_PARKING: ['Yes', 'No', "Unknown"]
};