import {
  Alert,
  Backdrop,
  Button,
  ButtonGroup,
  CircularProgress,
  Snackbar,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import JSZip from 'jszip';
import moment from 'moment';
import { useCallback, useContext, useMemo, useState } from 'react';

import { FinanceDocument, pdfBase64StringGenerator } from '../../components/FinanceDocument';
import FullScreenDialog from '../../components/FullScreenDialog';
import { PopupModal } from '../../components/PopupModal';
import AdminLayout from '../../layouts/AdminLayout';
import DataContext from '../../store/DataContext';
import coConverter from '../../utils/coConverter';
import errorHandler from '../../utils/errorHandler';
import fetchApi, { fetchEnhcApi } from '../../utils/fetchApi';

const CreditNoteListing = () => {
  const today = moment(new Date());
  const [fromValue, setFromValue] = useState(today);
  const [toValue, setToValue] = useState(today);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({ message: '', type: 'success' });
  const [creditNoteListing, setCreditNoteListing] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  // const pdfDataModel = (data) => ({
  //   columns: [
  //     { name: 'Description', length: 70 },
  //     { name: 'Qty', length: 10 },
  //     { name: 'Rate', length: 10 },
  //     { name: 'Amount', length: 10 }
  //   ],
  //   mainData: {
  //     address: invMain.invoiceaddress,
  //     postalCode: invMain.HirerPostal,
  //     remarks: `${invMain.invoicetitle} ${invMain.remarks} Invoice Reference No.: ${invMain.refinvoiceno}`,
  //     rentalAgreementNo: invMain.RentalAgreementNo
  //   },
  //   data: [
  //     ...invDetails.map((el) => ({
  //       Description:
  //         el.ItemGroup === 'Rental Rate'
  //           ? `${el.CarMake} ${el.CarModel} ${el.CarPlate}\nRental Period ${el.extraitemdate} - ${el.itemdeptdate}\n${el.sevicedesc}`
  //           : el.sevicedesc,
  //       ItemGroup: el.ItemGroup,
  //       Qty: el.itemqty,
  //       Rate: el.itemsellprice,
  //       Amount: el.totalitemsellprice
  //     }))
  //     // {
  //     //   Description: 'Desc',
  //     //   ItemGroup: 'Rental Rate',
  //     //   Qty: 100,
  //     //   Rate: 500,
  //     //   Amount: 20
  //     // },
  //     // {
  //     //   Description: 'DescCDW',
  //     //   ItemGroup: 'CDW',
  //     //   Qty: 7,
  //     //   Rate: 6,
  //     //   Amount: 42
  //     // }
  //   ],
  //   amount: data.amount,
  //   bookingNo: data.bookNo,
  //   company: data.company,
  //   docDate: data.date,
  //   docNo: data.documentNo,
  //   docType: 'Invoice',
  //   entity: data.hirer,
  //   gstRate: dataCtx.adminSettings.gstRate,
  //   id: el,
  //   isGstReg: data.company === 'Fresh Cars Pte Ltd'
  // });
  const dataCtx = useContext(DataContext);
  // Popup Modal

  const handleModalOpen = (data) => {
    setModalData(data);
    setModalOpen(true);
  };

  const handleModalClose = useCallback(() => {
    setModalOpen(false);
    setModalData({});
  }, [setModalOpen, setModalData]);

  const ModalContent = useCallback(
    () => (
      <>
        <Typography variant="h4" gutterBottom>
          Invoice
        </Typography>
        {modalData.data}
      </>
    ),
    [modalData.data]
  );

  const [dialogOpen, setDialogOpen] = useState(false);
  const closeDialog = useCallback(() => {
    if (window.confirm('Close without saving?')) {
      setDialogOpen(false);
    }
  }, [setDialogOpen]);
  const DialogContent = useCallback(
    () => (
      <Typography variant="h4" gutterBottom>
        dialogContentTitle
      </Typography>
    ),
    []
  );

  const getGstRate = (tempdate) => {
    const { gstRateList } = dataCtx;
    if (tempdate === '') {
      return 0;
    }
    const date = moment(tempdate);
    let currate = 0;

    for (let i = gstRateList.length; i > 0; i -= 1) {
      const { dateStart } = gstRateList[i - 1];
      const gstDate = moment(dateStart.seconds * 1000 + dateStart.nanoseconds / 1000000);
      const isAfter = date.isAfter(gstDate);
      const isSame = date.format('DD/MM/YYYY') === gstDate.format('DD/MM/YYYY');
      // console.log(gstDate.format('DD/MM/YYYY'));
      // console.log(date.format('DD/MM/YYYY'));
      if (isAfter || isSame) {
        currate = gstRateList[i - 1].rate;
        break;
      }
    }

    return currate;
  };

  const viewPdf = useCallback(
    async (params) => {
      setOpenBackdrop(true);
      const [[cnMain], cnDetails] = await fetchApi([
        { method: 'GetCreditNote', requestBody: { NoteCode: params.row.creditnotecode } },
        { method: 'GetCreditNoteDetails', requestBody: { NoteCode: params.row.creditnotecode } }
      ]);
      // Need to filter one more time because Road Runner invoicedetails API will return duplicate line item with a different car plate if the booking has COV before
      const cnDetailsFiltered = cnDetails.filter((el2) => {
        const cnFromMainListing = creditNoteListing.find(
          (invEl) => invEl.documentNo === params.row.documentNo
        );
        const correctCarPlate = cnFromMainListing.carplate;
        return el2.CarPlate === correctCarPlate;
      });
      handleModalOpen({
        data: (
          <FinanceDocument
            data={{
              columns: [
                { name: 'Description', length: 66 },
                { name: 'Qty', length: 8 },
                { name: 'Rate', length: 13 },
                { name: 'Amount', length: 13 }
              ],
              mainData: {
                address: cnMain.HirerAddress,
                postalCode: cnMain.HirerPostal,
                remarks: cnMain.Remarks,
                rentalAgreementNo: cnMain.RentalAgreementNo
              },
              data: [
                ...cnDetailsFiltered.map((el) => ({
                  Description: el.description,
                  Qty: el.qty,
                  Rate: el.unitprice,
                  Amount: el.totalprice
                }))
              ],
              amount: params.row.totalamt,
              bookingNo: params.row.Bookno,
              company: coConverter(params.row.company) === "nocompany" ?
                coConverter(params.row.refundVoucherCompany) : coConverter(params.row.company),
              docDate: params.row.creditnotedate,
              docNo: params.row.creditnotecode,
              docType: 'Credit Note',
              entity: params.row.HirerName,
              gstRate: getGstRate(params.row.creditnotedate),
              id: params.value,
              isGstReg: params.row.company === 'Fresh Cars Pte Ltd' && dataCtx.adminSettings.fcGst
            }}
          />
        ),
        docType: params.row.docType
      });
      setOpenBackdrop(false);
    },
    [dataCtx.adminSettings, creditNoteListing]
  );

  const tableColumns = [
    {
      field: 'id',
      headerName: 'Options',
      width: 100,
      renderCell: (params) => (
        <Button variant="contained" color="primary" size="small" onClick={() => viewPdf(params)}>
          Print
        </Button>
      ),
      type: 'string'
    },
    {
      field: 'company',
      headerName: 'Co',
      width: 50,
      type: 'string',
      sortable: true,
      renderCell: (params) => {
        return coConverter(params.row.company) === "nocompany" ?
          coConverter(params.row.refundVoucherCompany) : coConverter(params.row.company);
      }
    },
    {
      field: 'creditnotedate',
      headerName: 'Date',
      width: 120,
      type: 'date',
      valueFormatter: (params) =>
        new Date(params.value).toLocaleDateString('en-sg', {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        }),
      sortable: true
    },
    {
      field: 'Bookno',
      headerName: 'Booking',
      width: 150,
      type: 'string',
      editable: false
    },
    {
      field: 'HirerName',
      headerName: 'Hirer',
      width: 300,
      type: 'string',
      editable: false
    },
    {
      field: 'active',
      headerName: 'Status',
      width: 120,
      type: 'string',
      editable: false,
      renderCell: (params) => (
        <div style={{ color: params.value === 'VOIDED' ? 'red' : undefined }}>{params.value}</div>
      )
    },
    {
      field: 'creditnotecode',
      headerName: 'No.',
      width: 150,
      editable: false
    },
    {
      field: 'lineItemDescriptions',
      headerName: 'Description',
      width: 330,
      type: 'string',
      editable: false
    },
    {
      field: 'totalamt',
      headerName: 'Amount',
      width: 90,
      type: 'number',
      editable: false
    },
    {
      field: 'balanceamt',
      headerName: 'OS',
      width: 90,
      type: 'number',
      editable: false
    },
    {
      field: 'gst',
      headerName: 'GST',
      width: 90,
      type: 'number',
      editable: false,
      renderCell: (params) =>
        (
          params.row.totalamt *
          (dataCtx.adminSettings.gstRate / (100 + dataCtx.adminSettings.gstRate))
        ).toFixed(2)
    }
  ];

  const emailDownloadFn = useCallback(
    async (isEmail) => {
      setOpenBackdrop(true);
      const base64PromiseArray = [];

      try {
        const cnDetailsArray = await fetchEnhcApi('GetCreditNoteItemsListing', {
          // BookNo: '',
          // StartDate: moment(fromValue).format('DD/MM/yyyy'),
          // EndDate: moment(toValue).format('DD/MM/yyyy'),
          creditnotecode: '',
          'createdate-from': moment(fromValue).format('yyyy-MM-DD'),
          'createdate-to': moment(toValue).format('yyyy-MM-DD')
          // Company: '',
          // Hirer: '',
          // CarPlate: '',
          // Status: '',
        });
        // Generate Base64 strings for all document PDFs

        selectedRows.forEach((el) => {
          const cnMain = creditNoteListing.find((cnMainEl) => cnMainEl.creditnotecode === el);
          const cnDetails = cnDetailsArray.filter(
            (cnDetailsEl) => cnDetailsEl.creditnotecode === el
          );
          base64PromiseArray.push(
            pdfBase64StringGenerator({
              columns: [
                { name: 'Description', length: 70 },
                { name: 'Qty', length: 10 },
                { name: 'Rate', length: 10 },
                { name: 'Amount', length: 10 }
              ],
              mainData: {
                address: cnMain.HirerAddress,
                postalCode: cnMain.HirerPostal,
                remarks: cnMain.Remarks,
                rentalAgreementNo: cnMain.RentalAgreementNo
              },
              data: [
                ...cnDetails.map((el) => ({
                  Description: el.description,
                  ItemGroup: '',
                  Qty: el.qty,
                  Rate: el.unitprice,
                  Amount: el.totalprice
                }))
                // {
                //   Description: 'Desc',
                //   ItemGroup: 'Rental Rate',
                //   Qty: 100,
                //   Rate: 500,
                //   Amount: 20
                // },
                // {
                //   Description: 'DescCDW',
                //   ItemGroup: 'CDW',
                //   Qty: 7,
                //   Rate: 6,
                //   Amount: 42
                // }
              ],
              amount: cnMain.totalamt,
              bookingNo: cnMain.Bookno,
              company:  coConverter(cnMain.company) === "nocompany" ?
            coConverter(cnMain.refundVoucherCompany) : coConverter(cnMain.company),
              docDate: new Date(cnMain.creditnotedate),
              docNo: cnMain.creditnotecode,
              docType: 'Credit Note',
              entity: cnMain.HirerName,
              gstRate: getGstRate(cnMain.creditnotedate),
              id: el,
              isGstReg: cnMain.company === 'Fresh Cars Pte Ltd' && dataCtx.adminSettings.fcGst
            })
          );
        });

        const result = await Promise.allSettled(base64PromiseArray);

        result.forEach((el) => {
          if (el.status !== 'fulfilled') {
            throw Error('Some promises of conversion to base 64 strings were not fulfilled.');
          }
        });

        const mappedResult = result.map((el, i) => {
          const invData = creditNoteListing.find((invEl) => invEl.id === selectedRows[i]);
          return {
            company: invData.company,
            HirerName: invData.HirerName,
            email: invData.email,
            documentNo: selectedRows[i],
            pdfBase64String: el.value
          };
        });

        if (isEmail) {
          const resp = await fetchApi([{ method: 'SendInvoiceEmail', requestBody: mappedResult }]);
          console.log(`Done sending emails: ${resp[0].value.d}`);
          handleSnackbar(`Your emails were successfully sent. ${resp[0].value.d}`, 'success');
        } else {
          const zip = new JSZip();
          mappedResult.forEach((el) => {
            zip.file(`${el.documentNo}-${el.HirerName}.pdf`, el.pdfBase64String, { base64: true });
          });
          const zippedBase64 = await zip.generateAsync({ type: 'base64' });
          window.location.href = `data:application/zip;base64,${zippedBase64}`;
          handleSnackbar('Your files were downloaded successfully.', 'success');
        }
      } catch (e) {
        errorHandler('Send All Credit Note Emails', 'SendCreditNoteEmails', e);
      }
      setOpenBackdrop(false);
    },
    [dataCtx.adminSettings, creditNoteListing, setOpenBackdrop, selectedRows, fromValue, toValue]
  );

  const tooltipNotes = ['1. x.'];
  const filters = [
    {
      name: 'FC',
      value: { column: 'company', operatorValue: 'contains', value: 'Fresh Cars Pte Ltd' }
    },
    {
      name: 'OC',
      value: { column: 'company', operatorValue: 'contains', value: 'Orange Cars' }
    },
    {
      name: 'RT',
      value: { column: 'company', operatorValue: 'contains', value: 'Renty Pte Ltd' }
    },
    {
      name: 'OE',
      value: { column: 'company', operatorValue: 'contains', value: 'Orange Express' }
    },
    {
      name: 'CP',
      value: { column: 'company', operatorValue: 'contains', value: 'Clear Path Rentals' }
    }
  ];
  const tabNames = [];
  const searches = useMemo(
    () => [
      // { name: 'New', disabled: false, fn: () => console.log('new clicked') },
      {
        name: 'Email',
        disabled: true,
        fn: () => emailDownloadFn(true)
      },
      {
        name: 'Download',
        disabled:
          selectedRows.length === 0 ||
          creditNoteListing
            .filter((el) => selectedRows.findIndex((el2) => el2 === el.creditnotecode) >= 0)
            .some((el3) => el3.active === 'VOIDED'),
        fn: () => emailDownloadFn(false)
      }
    ],
    [emailDownloadFn, creditNoteListing, selectedRows]
  );

  const TopRightOptions = useCallback(
    () => (
      <ButtonGroup size="medium" variant="contained" aria-label="outlined primary button group">
        {searches.map((el) => (
          <Button disabled={el.disabled} key={el.name} onClick={el.fn}>
            {el.name}
          </Button>
        ))}
      </ButtonGroup>
    ),
    [searches]
  );

  const fetchCreditNoteListing = useCallback(async () => {
    setOpenBackdrop(true);
    try {
      const response = await fetchEnhcApi('GetCreditNoteWithLineItemDescriptionsListing', {
        'createdate-from': moment(fromValue).format('yyyy-MM-DD'),
        'createdate-to': moment(toValue).format('yyyy-MM-DD')
      });

      setCreditNoteListing(
        response.map((el) => ({
          ...el,
          id: el.creditnotecode,
          balanceamt: parseFloat(el.balanceamt, 10),
          creditnotedate: el.creditnotedate,
          active: el.active === '1' ? 'ACTIVE' : 'VOIDED',
          totalamt: parseFloat(el.totalamt, 10),
          refundVoucherCompany: el.RefundVoucherCompany
        }))
      );
    } catch (e) {
      window.alert(`An error occurred: ${e}`);
    }
    setOpenBackdrop(false);
  }, [fromValue, toValue]);

  const handleSnackbar = (message, type) => {
    setSnackbarInfo({ message, type });
    setOpenSnackbar(true);
  };

  const fromValueHandleChange = useCallback(
    (newValue) => {
      setFromValue(newValue);
      setCreditNoteListing([]);
    },
    [setFromValue, setCreditNoteListing]
  );

  const toValueHandleChange = useCallback(
    (newValue) => {
      setToValue(newValue);
      setCreditNoteListing([]);
    },
    [setToValue, setCreditNoteListing]
  );

  const code = (
    <Stack alignItems="center" direction="row" mt={2} mb={2} spacing={2}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DesktopDatePicker
          disableMaskedInput
          label="From"
          inputFormat="DD/MM/yyyy"
          value={fromValue}
          onChange={fromValueHandleChange}
          renderInput={(params) => <TextField {...params} />}
        />
        <DesktopDatePicker
          disableMaskedInput
          label="To"
          inputFormat="DD/MM/yyyy"
          value={toValue}
          onChange={toValueHandleChange}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      <Button
        onClick={async () => {
          if (!fromValue.isValid()) {
            window.alert('Please enter a valid from date.');
            return;
          }

          if (!toValue.isValid()) {
            window.alert('Please enter a valid to date.');
            return;
          }

          if (toValue.diff(fromValue, 'seconds') < 0) {
            window.alert('From date must be earlier than to date.');
            return;
          }

          await fetchCreditNoteListing();
        }}
        variant="contained"
      >
        Search
      </Button>
    </Stack>
  );

  return (
    <>
      <FullScreenDialog
        dialogOpen={dialogOpen}
        handleDialogClose={closeDialog}
        data={{}}
        code={DialogContent}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={openSnackbar}
        autoHideDuration={10000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarInfo.type}>
          {snackbarInfo.message}
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <PopupModal openState={modalOpen} closeHandler={handleModalClose} code={ModalContent} />
      <AdminLayout
        code={code}
        dataGridCheckbox
        title="Credit Note Listing"
        tableColumns={tableColumns}
        data={creditNoteListing}
        tooltipNotes={tooltipNotes}
        filters={filters}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        tabNames={tabNames}
        topRightOptions={<TopRightOptions />}
      />
    </>
  );
};

export default CreditNoteListing;
