import { getAuth } from 'firebase/auth';
import { useCallback, useEffect, useReducer, useRef } from 'react';
import { carTaskService } from '../../services/carTaskService';

const initialState = {
  tasks: [],
  isLoading: false,
  error: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_LOADING':
      return { ...state, isLoading: action.payload };
    case 'SET_TASKS':
      return { 
        ...state, 
        tasks: action.payload,
        isLoading: false 
      };
    case 'SET_ERROR':
      return { 
        ...state, 
        error: action.payload,
        isLoading: false 
      };
    default:
      return state;
  }
};

export const useCarTask = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const mounted = useRef(true);

  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  const fetchTasks = useCallback(async (assignTo) => {
    if (!mounted.current) return;
    dispatch({ type: 'SET_LOADING', payload: true });
    
    try {
      const response = await carTaskService.getCarTasks(assignTo);
      if (mounted.current) {
        dispatch({ type: 'SET_TASKS', payload: response });
      }
    } catch (err) {
      if (mounted.current) {
        dispatch({ type: 'SET_ERROR', payload: err.message });
      }
    }
  }, []);

  const assignTeam = useCallback(async (car, team) => {
    const username = getAuth().currentUser?.email;
    if (!username) throw new Error('User not authenticated');

    const taskData = {
      carplate: car.licensePlate,
      status: 'Todo',
      assignTo: team,
      assignBy: username,
      remark: ''
    };
    
    await carTaskService.createTask(taskData);
  }, []);

  const updateTaskStatus = useCallback(async (taskId, task) => {
    try {
      await carTaskService.updateTaskStatus(taskId, task);
      await fetchTasks(task.assignTo);
    } catch (error) {
      console.error('Error updating task status:', error);
      throw error;
    }
  }, [fetchTasks]);

  const updateTaskRemark = useCallback(async (taskId, task) => {
    try {
      await carTaskService.updateTaskStatus(taskId, task);
      await fetchTasks(task.assignTo);
    } catch (error) {
      console.error('Error updating task remark:', error);
      throw error;
    }
  }, [fetchTasks]);

  const deleteTask = useCallback(async (taskId, task) => {
    try {
      await carTaskService.deleteTask(taskId);
      await fetchTasks(task.assignTo);
    } catch (error) {
      console.error('Error deleting task:', error);
      throw error;
    }
  }, [fetchTasks]);

  return {
    ...state,
    assignTeam,
    updateTaskStatus,
    updateTaskRemark,
    deleteTask,
    refreshTasks: fetchTasks
  };
};