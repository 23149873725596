import { Add } from '@mui/icons-material';
import { Box, Button, Card, CardContent, CardHeader, CircularProgress, Grid } from '@mui/material';
import { memo } from 'react';
import { ExpandableParkingList } from './ExpandableParkingList';
import { PaginatedContent, usePagination } from './PaginatedContent';

const OperationsView = memo(({
  cars,
  onUpdateCar,
  onAddCar,
  onDriveOut,
  userDept,
  onEdit,
  isLoading
}) => {
  const pagination = usePagination(10);

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
          <Button 
            variant="contained" 
            startIcon={<Add />} 
            onClick={onAddCar}
            disabled={isLoading}
          >
            Add Car
          </Button>
        </Box>
        <Card elevation={3}>
          <CardHeader
            title="Cars"
            action={
              <Box sx={{ color: 'text.secondary', mr: 2 }}>
                Total cars: {cars.length}
              </Box>
            }
          />
          <CardContent>
            <PaginatedContent
              items={cars}
              {...pagination}
              totalLabel="cars"
              renderContent={(paginatedCars) => (
                <ExpandableParkingList
                  onEdit={onEdit}
                  view="operations"
                  cars={paginatedCars}
                  onDriveOut={onDriveOut}
                  userDept={userDept}
                  isLoading={isLoading}
                />
              )}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
});
export default OperationsView;