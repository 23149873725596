import { Container, Grid, Stack, Typography } from '@mui/material';
import { useContext } from 'react';
import Page from '../components/Page';
import {
  AppConversionRates,
  AppCurrentSubject,
  AppCurrentVisits,
  AppItemOrders,
  AppNewsUpdate,
  AppNewUsers,
  AppOrderTimeline,
  AppRegistrations,
  AppTasks,
  AppTrafficBySite,
  AppWeeklySales,
  MailingListCard
} from '../components/_dashboard/app';
import AuthContext from '../store/AuthContext';

export default function DashboardApp() {
  const authCtx = useContext(AuthContext);

  if (['runner', 'towing', 'washer'].includes(authCtx.userDept)) {
    return (
      <Typography variant="h4">
        Hi {authCtx.authObj.currentUser.displayName}, welcome back
      </Typography>
    );
  }

  return (
    <Page title="Dashboard | Fresh Cars">
      <Container maxWidth="xl">
        <Stack
          direction="column"
          alignItems="flex-start"
          justify-content="flex-start"
          spacing={2}
          sx={{ pb: 3 }}
        >
          <Typography variant="h4">
            Hi {authCtx.authObj.currentUser.displayName}, welcome back
          </Typography>
        </Stack>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWeeklySales />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppNewUsers />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppItemOrders />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <MailingListCard />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppRegistrations />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

export const routeName = 'dashboard';
