import { Alert, Button, ButtonGroup, Snackbar } from '@mui/material';
import { useState } from 'react';
import { useCarTask } from '../../hooks/parking/useCarTask';

export const TeamToggleButtons = ({ car, refreshCars }) => {
  const { assignTeam } = useCarTask();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const handleTeamClick = async (team) => {
    try {
      await assignTeam(car, team);
      setSnackbar({
        open: true,
        message: `Successfully assigned to ${team} team`,
        severity: 'success'
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.message || `Failed to assign to ${team} team`,
        severity: 'error'
      });
    }
  };

  return (
    <>
      <ButtonGroup size="small" variant="outlined">
        <Button onClick={() => handleTeamClick('Towing')}>
          Towing
        </Button>
        <Button onClick={() => handleTeamClick('Runner')}>
          Runner
        </Button>
        <Button onClick={() => handleTeamClick('Washer')}>
          Washer
        </Button>
      </ButtonGroup>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert 
          onClose={() => setSnackbar(prev => ({ ...prev, open: false }))} 
          severity={snackbar.severity}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default TeamToggleButtons;