import { Delete, DirectionsCar, ExitToApp, ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import dayjs from 'dayjs';
import { memo, useCallback, useState } from 'react';

const TaskItem = memo(
  ({
    task,
    isExpanded,
    onExpand,
    onDriveOut,
    onStatusChange,
    onAddRemark,
    onDeleteTask,
  }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [deleteDialog, setDeleteDialog] = useState(false);

    const handleExpand = useCallback(() => {
      onExpand(task.id);
    }, [task.id, onExpand]);

    const handleDeleteConfirm = useCallback(() => {
      onDeleteTask(task.id, task);
      setDeleteDialog(false);
    }, [task.id, onDeleteTask]);

    const calculateDuration = useCallback((time) => {
      return dayjs(time).format('DD MMM YYYY, HH:mm');
    }, []);

    const renderStatusAction = useCallback(
      () => (
        <FormControl size="small" sx={{ minWidth: 120 }}>
          <Select
            value={task.status || 'Todo'}
            onChange={(e) => onStatusChange(task, e.target.value)}
            sx={{ height: '32px' }}
          >
            <MenuItem value="Todo">Todo</MenuItem>
            <MenuItem value="Done">Done</MenuItem>
          </Select>
        </FormControl>
      ),
      [task, onStatusChange]
    );

    const renderActions = () => {
      const baseActions = (
        <>
          {renderStatusAction()}
          {onAddRemark && (
            <Button variant="outlined" size="small" onClick={() => onAddRemark(task)}>
              {task.remark ? 'Edit Remark' : 'Add Remark'}
            </Button>
          )}
          {onDeleteTask && (
            <Tooltip title="Delete Task">
              <IconButton size="small" color="error" onClick={() => setDeleteDialog(true)}>
                <Delete fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </>
      );

      if (isMobile) {
        return (
          <Stack spacing={1} width="100%">
            {task.parkingInfo?.lotId && (
              <ButtonGroup size="small" variant="outlined" fullWidth>
                <Button
                  onClick={handleExpand}
                  startIcon={isExpanded ? <ExpandLess /> : <ExpandMore />}
                >
                  {isExpanded ? 'Hide' : 'Map'}
                </Button>
                <Button onClick={() => onDriveOut(task)} startIcon={<ExitToApp />}>
                  Exit
                </Button>
              </ButtonGroup>
            )}
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>{baseActions}</Box>
          </Stack>
        );
      }

      return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <ButtonGroup size="small" variant="outlined">
            {task.parkingInfo?.lotId && (
              <>
                <Tooltip title={isExpanded ? 'Hide Map' : 'Show Map'}>
                  <IconButton size="small" onClick={handleExpand}>
                    {isExpanded ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </Tooltip>
                <Tooltip title="Drive Out">
                  <IconButton size="small" onClick={() => onDriveOut(task)}>
                    <ExitToApp fontSize="small" />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </ButtonGroup>

          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>{baseActions}</Box>
        </Box>
      );
    };

    return (
      <>
        <Paper
          key={task.id}
          elevation={1}
          sx={{
            p: 1.5,
            position: 'relative',
            '&:hover': { bgcolor: 'action.hover' }
          }}
        >
          <Stack spacing={1.5}>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  mb: 1,
                  flexWrap: 'wrap'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    minWidth: isMobile ? '100%' : 'auto'
                  }}
                >
                  <DirectionsCar fontSize="small" />
                  <Typography variant="subtitle2">{task.carplate}</Typography>
                </Box>
                {task.parkingInfo?.lotId && (
                  <Typography variant="body2" color="textSecondary" sx={{ flexGrow: 1 }}>
                    Section {task.parkingInfo.section} Lot {task.parkingInfo.lotId}
                  </Typography>
                )}
              </Box>

              <Stack direction={isMobile ? 'column' : 'row'} spacing={1} sx={{ mb: 1 }}>
                <Chip
                  size="small"
                  label={`Assigned by ${task.assignBy} at ${calculateDuration(task.createdAt)}`}
                  variant="outlined"
                  sx={{ maxWidth: '100%' }}
                />
                {task.parkingInfo?.seasonalParking && (
                  <Chip
                    size="small"
                    label={`Season: ${task.parkingInfo.seasonalParking}`}
                    color={
                      task.parkingInfo.seasonalParking === 'Yes'
                        ? 'success'
                        : task.parkingInfo.seasonalParking === 'No'
                        ? 'error'
                        : 'default'
                    }
                  />
                )}
                <Chip
                  size="small"
                  label={`Status: ${task.status}`}
                  color={task.status === 'Done' ? 'success' : 'warning'}
                />
              </Stack>

              {task.remark && (
                <Typography
                  variant="body2"
                  sx={{
                    p: 1,
                    bgcolor: 'action.hover',
                    borderRadius: 1,
                    mb: 1
                  }}
                >
                  {task.remark}
                </Typography>
              )}
            </Box>

            {renderActions()}
          </Stack>

        </Paper>

        <Dialog open={deleteDialog} onClose={() => setDeleteDialog(false)}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this task? This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteDialog(false)}>Cancel</Button>
            <Button onClick={handleDeleteConfirm} color="error" variant="contained">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
);

export default TaskItem;
