import { Stack } from "@mui/material";
import { memo, useCallback, useState } from "react";
import CarItem from "./CarItem";

export const ExpandableParkingList = memo(({
  onEdit,
  onDelete,
  cars,
  onDriveOut,
  userDept,
  view,
}) => {
  const [expandedCarId, setExpandedCarId] = useState(null);

  const handleExpand = useCallback((carId) => {
    setExpandedCarId(prev => prev === carId ? null : carId);
  }, []);


  return (
    <Stack spacing={1}>
      {cars.map((car) => (
        <CarItem
          key={car.id}
          car={car}
          isExpanded={expandedCarId === car.id}
          onExpand={handleExpand}
          onEdit={onEdit}
          onDriveOut={onDriveOut}
          userDept={userDept}
          view={view}
        />
      ))}
    </Stack>
  );
});
