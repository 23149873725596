import { Stack } from '@mui/material';
import { memo, useCallback, useState } from 'react';
import TaskItem from './TaskItem';

const TaskExpandableList = memo(({
  tasks,
  onDriveOut,
  onStatusChange,
  onDeleteTask,
  onAddRemark,
}) => {
  const [expandedTaskId, setExpandedTaskId] = useState(null);

  const handleExpand = useCallback((taskId) => {
    setExpandedTaskId(prev => prev === taskId ? null : taskId);
  }, []);

  return (
    <Stack spacing={1}>
      {tasks.map((task) => (
        <TaskItem
          key={task.id}
          task={task}
          isExpanded={expandedTaskId === task.id}
          onExpand={handleExpand}
          onDriveOut={onDriveOut}
          onDeleteTask={onDeleteTask}
          onStatusChange={onStatusChange}
          onAddRemark={onAddRemark}
        />
      ))}
    </Stack>
  );
});

export default TaskExpandableList;
