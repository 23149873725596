import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography
} from '@mui/material';
import { useContext, useState } from 'react';
import CarPlateAutocomplete from '../../components/CarPlateAutocomplete';
import NormalDialog from '../../components/NormalDialog';
import OperationsView from '../../components/parking/OperationsView';
import { ParkingSearch } from '../../components/parking/ParkingSearch';
import TeamView from '../../components/parking/TeamView';
import { useParking } from '../../hooks/parking/useParking';
import { parkingService } from '../../services/parkingService';
import AuthContext from '../../store/AuthContext';
import { getSectionFromLot, PARKING_CONSTANTS } from '../../utils/parkingUtils';

const ParkingManagement = () => {
  const {
    cars,
    filteredCars,
    isLoadingParks,
    carParks,
    handleUpdateCar,
    handleDriveOut,
    handleSearch,
    isLoading,
    closeSnackbar,
    snackbar,
    refreshCars,
    openSnackbar
  } = useParking();

  const [selectedView, setSelectedView] = useState('operations');
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openDriveOutDialog, setOpenDriveOutDialog] = useState(false);
  const [driveOutCar, setDriveOutCar] = useState(null);
  const [newCar, setNewCar] = useState({
    licensePlate: '',
    lotId: '',
    seasonalParking: PARKING_CONSTANTS.SEASONAL_PARKING[2]
  });
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [carToEdit, setCarToEdit] = useState(null);
  const { userDept } = useContext(AuthContext);

  const isOperationalUser = userDept === 'superadmin' || userDept === 'operation';

  const handleDriveOutConfirm = async () => {
    if (!driveOutCar) return;

    try {
      await handleDriveOut(driveOutCar.licensePlate);
      setOpenDriveOutDialog(false);

      await refreshCars();
      setDriveOutCar(null);
    } catch (error) {
      openSnackbar({
        message: error.message || 'Failed to remove car',
        severity: 'error'
      });
    }
  };

  const handleAddCar = async () => {
    const section = getSectionFromLot(newCar.lotId);

    const carData = {
      licensePlate: newCar.licensePlate.toUpperCase(),
      section,
      lotId: newCar.lotId,
      seasonalParking: newCar.seasonalParking,
      time: new Date().toISOString(),
      carParkId: newCar.carParkId
    };

    try {
      if (carToEdit) {
        // Handle edit case
        await parkingService.updateCar(carToEdit.id, {
          ...carData,
          id: carToEdit.id // Make sure to include the id for updates
        });
      } else {
        // Handle add case
        await parkingService.addCar(carData);
      }
      await refreshCars();
      setOpenAddDialog(false);
      setOpenEditDialog(false);
      setNewCar({
        licensePlate: '',
        lotId: '',
        seasonalParking: PARKING_CONSTANTS.SEASONAL_PARKING[2],
        carParkId: ''
      });
      openSnackbar({
        message: carToEdit ? 'Car updated successfully' : 'Car added successfully',
        severity: 'success'
      });
    } catch (error) {
      openSnackbar({
        message: error.message || 'Failed to process car',
        severity: 'error'
      });
    }
  };

  const handleEditCar = async (car) => {
    console.log(car);
    setCarToEdit(car);
    setNewCar({
      licensePlate: car.licensePlate,
      lotId: car.lotId,
      seasonalParking: car.seasonalParking,
      carParkId: car.carParkId
    });

    await refreshCars();
    setOpenEditDialog(true);
  };

  const renderAddCarForm = () => {
    return (
      <Box sx={{ pt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
        <CarPlateAutocomplete
          value={newCar.licensePlate}
          onChange={(value) => setNewCar({ ...newCar, licensePlate: value })}
        />
        {!newCar.licensePlate && (
          <Alert severity="warning">Car licensePlate not found. You can still submit.</Alert>
        )}

        <FormControl fullWidth>
          <InputLabel>Car Park</InputLabel>
          <Select
            value={newCar.carParkId || ''}
            label="Car Park"
            onChange={(e) => setNewCar({ ...newCar, carParkId: e.target.value })}
            disabled={isLoadingParks}
          >
            {carParks.map((park) => (
              <MenuItem key={park.id} value={park.id}>
                {park.name} - {park.address}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          label="Lot Number"
          value={newCar.lotId}
          onChange={(e) => setNewCar({ ...newCar, lotId: e.target.value })}
          fullWidth
          required
          type="number"
          helperText={
            getSectionFromLot(newCar.lotId)
              ? `This lot belongs to Section ${getSectionFromLot(newCar.lotId)}`
              : 'Enter a valid lot number'
          }
        />
        {newCar.lotId && !getSectionFromLot(newCar.lotId) && (
          <Alert severity="warning">
            Lot number not found in any section. You can still submit.
          </Alert>
        )}

        {cars.find((car) => car.lotId === newCar.lotId) && (
          <Alert severity="error">
            This lot is currently occupied. Adding a car here will require the current car to be
            moved.
          </Alert>
        )}

        <FormControl fullWidth>
          <InputLabel>Is updated season parking</InputLabel>
          <Select
            value={newCar.seasonalParking}
            label="Is updated season parking"
            onChange={(e) => setNewCar({ ...newCar, seasonalParking: e.target.value })}
          >
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
            <MenuItem value={PARKING_CONSTANTS.SEASONAL_PARKING[2]}>
              {PARKING_CONSTANTS.SEASONAL_PARKING[2]}
            </MenuItem>
          </Select>
        </FormControl>

        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
          {carToEdit ? (
            <>
              <Button onClick={() => setOpenEditDialog(false)}>Cancel</Button>
              <Button onClick={handleAddCar} variant="contained">
                Edit Car
              </Button>
            </>
          ) : (
            <>
              <Button onClick={() => setOpenAddDialog(false)}>Cancel</Button>
              <Button
                onClick={handleAddCar}
                variant="contained"
                disabled={!newCar.carParkId || isLoadingParks}
              >
                Add Car
              </Button>
            </>
          )}
        </Box>
      </Box>
    );
  };

  const renderDriveOutConfirmation = () => (
    <Box sx={{ p: 3, display: 'flex', flexDirection: 'column', gap: 3 }}>
      <Typography variant="h6">Drive Out Confirmation</Typography>

      <Alert severity="info" sx={{ mb: 2 }}>
        Car Details:
        <Typography component="div" sx={{ mt: 1 }}>
          License Plate: {driveOutCar?.licensePlate}
          <br />
          Lot: {driveOutCar?.lotId}
          <br />
          Section: {getSectionFromLot(driveOutCar?.lotId)}
        </Typography>
      </Alert>

      <Typography>
        Are you sure you want to mark this car as driven out? This action cannot be undone.
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
        <Button onClick={() => setOpenDriveOutDialog(false)}>Cancel</Button>
        <Button onClick={handleDriveOutConfirm} variant="contained" color="primary">
          Confirm Drive Out
        </Button>
      </Box>
    </Box>
  );

  const renderView = () => {
    if (isLoading) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      );
    }

    const commonProps = {
      cars: filteredCars,
      onAddCar: () => setOpenAddDialog(true),
      onDriveOut: (car) => {
        setDriveOutCar(car);
        setOpenDriveOutDialog(true);
      },
      isOperationalUser,
      userDept,
      isLoading
    };

    switch (selectedView) {
      case 'operations':
        return (
          <OperationsView {...commonProps} onEdit={handleEditCar} onUpdateCar={handleUpdateCar} />
        );
      case 'towing':
        return <TeamView {...commonProps} title="Runner Team Tasks" team="Towing" />;
      case 'runner':
        return <TeamView {...commonProps} title="Runner Team Tasks" team="Runner" />;
      case 'washer':
        return <TeamView {...commonProps} title="Cars to Wash" team="Washer" />;
      default:
        return null;
    }
  };

  return (
    <Box sx={{ p: 3, maxWidth: 'xl', mx: 'auto' }}>
      <Box sx={{ mb: 3, display: 'flex', gap: 2 }}>
        <Button
          variant={selectedView === 'operations' ? 'contained' : 'outlined'}
          onClick={() => setSelectedView('operations')}
        >
          All Cars Listing
        </Button>
        {(isOperationalUser || userDept === 'towing') && (
          <Button
            variant={selectedView === 'towing' ? 'contained' : 'outlined'}
            onClick={() => setSelectedView('towing')}
          >
            Towing Team
          </Button>
        )}

        {(isOperationalUser || userDept === 'runner') && (
          <Button
            variant={selectedView === 'runner' ? 'contained' : 'outlined'}
            onClick={() => setSelectedView('runner')}
          >
            Runner Team
          </Button>
        )}
        {(isOperationalUser || userDept === 'washer') && (
          <Button
            variant={selectedView === 'washer' ? 'contained' : 'outlined'}
            onClick={() => setSelectedView('washer')}
          >
            Washer Team
          </Button>
        )}
      </Box>

      <ParkingSearch carParks={carParks} onSearch={handleSearch} />
      {renderView()}

      {/* Add Car Dialog */}
      <NormalDialog
        dialogOpen={openAddDialog}
        handleDialogClose={() => {
          setOpenAddDialog(false);
          setNewCar({
            licensePlate: '',
            lotId: '',
            carParkId: '',
            seasonalParking: PARKING_CONSTANTS.SEASONAL_PARKING[2]
          });
        }}
        data={{
          hirer: 'Parking Management',
          docType: 'Add New Car',
          editMode: false
        }}
        code={renderAddCarForm}
      />

      {/* Edit Car Dialog */}
      <NormalDialog
        dialogOpen={openEditDialog}
        handleDialogClose={() => {
          setOpenEditDialog(false);
          setCarToEdit(null);
          setNewCar({
            licensePlate: '',
            lotId: '',
            carParkId: '',
            seasonalParking: PARKING_CONSTANTS.SEASONAL_PARKING[2]
          });
        }}
        data={{
          hirer: 'Parking Management',
          docType: 'Edit Car',
          editMode: true,
          docNo: carToEdit?.licensePlate
        }}
        code={renderAddCarForm}
      />

      {/* Drive Out Dialog */}
      <NormalDialog
        dialogOpen={openDriveOutDialog}
        handleDialogClose={() => {
          setOpenDriveOutDialog(false);
          setDriveOutCar(null);
        }}
        data={{
          hirer: 'Parking Management',
          docType: 'Drive Out Car',
          editMode: true,
          docNo: driveOutCar?.licensePlate
        }}
        code={renderDriveOutConfirmation}
      />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => closeSnackbar()}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={() => closeSnackbar()} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ParkingManagement;
